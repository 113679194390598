<script setup>
import { onMounted, ref } from 'vue'
import { useSlugify } from '@/composables/useSlugify.js'
import InputLabel from '@/Components/InputLabel.vue'
import InputError from '@/Components/InputError.vue'

defineProps({
    label: {
        required: false,
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'text',
    },
    error: {
        type: String,
        default: null,
    },
})

const model = defineModel({
    type: String,
    required: true,
})

const input = ref(null)

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus()
    }
})

defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <div v-if="label">
        <div class="mt-2">
            <InputLabel :value="label"/>
            <input
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                :id="useSlugify(label)"
                v-model="model"
                v-bind="$attrs"
                :type="type"
                ref="input"
            />
        </div>
    </div>

    <input
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        v-else
        v-model="model"
        v-bind="$attrs"
        :type="type"
        ref="input"
    />

    <InputError v-if="error" class="mt-2" :message="error" />
</template>
