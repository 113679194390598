export const useSlugify = (input) => {
    if (!input)
        return ''

    return input
        .toLowerCase()
        .trim()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9\s-]/g, ' ').trim()
        .replace(/[\s-]+/g, '-')
}
